.add-product form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  width: min(100%, 360px);
  margin: 0 auto;
  padding: 1rem;
  background-color: var(--bs-gray-400);
}

.add-product form input[type="text"] {
  display: block;
  border-radius: 10px;
}

.add-product form .required::after {
  content: "*";
  color: var(--bs-danger);
  margin-inline: 0.1rem;
}

.add-product form .input-group-text {
  border-radius: 10px;
}

.add-product .image-preview {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 0.25rem;
}

.add-product .image-preview>div {
  position: relative;
}

.add-product .image-preview button {
  position: absolute;
  border-radius: 50%;
  padding: 0px;
  right: 0;
  top: 0;
  width: 20px;
}

.add-product .datepicker-wrapper {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}

.add-product .datepicker-wrapper p {
  margin: 0px;
}

.add-product .datepicker-wrapper .label {
  flex-basis: 25%;
}

.add-product .datepicker-wrapper>div:last-child {
  flex-grow: 1;
}