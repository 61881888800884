.login {
  position: relative;
  padding: 1rem;
  width: min(100%, 750px);
}

.login .login-card form>div {
  margin-block: 1rem;
}

.login .login-form .input-group {
  margin-bottom: 1rem;
}

.login .login-card button {
  display: block;
  padding: 0.5em 1em;
  background-color: var(--primary-color);
  color: var(--primary-contrast-text);
  border: 0px;
  border-radius: 8px;
  margin: 1rem auto;
  width: 100%;
}

.login .dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}

.login .dropdown-toggle {
  --bs-btn-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
}