.products {
  padding: 1rem;
  display: grid;
  grid-template-rows: 60px minmax(0, 1fr) 60px;
  height: 100%;
}

.products .nav-tabs .nav-link {
  color: var(--bs-body-color);
}

.products .nav-tabs .nav-link.active {
  background-color: var(--bs-secondary-bg);
  color: var(--bs-primary);
}

.products .tab-content {
  overflow: auto;
}

.products .add-products button {
  margin-block: 1rem;
  width: 100%;
}