.logo-heading {
  display: grid;
  grid-template-columns: 3rem max-content;
  align-items: center;
  gap: 8px;
}

.logo-heading h3 {
  margin: 0px;
  font-size: 1.5rem;
}

.logo-heading img {
  display: inline-block;
  vertical-align: middle;
}
