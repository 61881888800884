header.slotted-header {
  display: grid;
  padding: 0.75em 1em;
  grid-template-columns: 2rem minmax(0, 1fr) 2rem;
  align-items: center;
  gap: 8px;
  background-color: white;
  z-index: 100;
  border-bottom: 1px solid #d9d9d9;
  box-shadow: 0px 1px 1px 0px #00000008;
}

header.slotted-header button {
  border-radius: 0px;
}