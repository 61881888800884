.home {
  display: grid;
  grid-template-rows: 60px minmax(0, 1fr) 60px;
  height: 100vh;
}

.home header {
  display: grid;
  padding: 1em;
  grid-template-columns: 48px minmax(0, 1fr) 48px;
  text-align: center;
  align-items: center;
}

.home header button {
  border-radius: 50%;
  aspect-ratio: 1/1;
}

.home main {
  overflow: auto;
  padding-block: 1rem;
}

.home footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
  font-size: 2rem;
  text-align: center;
}

.home footer>* {
  flex: 1 1 auto;
  background-color: var(--bs-body-bg);
  color: var(--bs-secondary);
}

.home footer>.active {
  flex: 1 1 auto;
  background-color: var(--bs-secondary-bg);
  color: var(--bs-primary);
}