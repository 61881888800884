$primary: #d33e22;
$secondary: #f58634;
$tertiary: #052f5f;
$success: #1ca146;
$danger: #c00a15;
$gray-600: #5e5f60;

$font-family-sans-serif: "Kanit", -apple-system, system-ui, BlinkMacSystemFont,
  "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
// $enable-validation-icons: false;

@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons.css";
