.product-item {
  display: grid;
  grid-template-columns: min(25%, 95px) minmax(0, 1fr);
  border-bottom: 1px solid var(--bs-gray);
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
}

.product-item p {
  font-size: 0.75rem;
  margin: 0px;
}

.product-item img {
  border-radius: 6px;
  aspect-ratio: 1/1;
  object-fit: cover;
  overflow-clip-margin: unset;
}

.product-item button {
  margin-right: 0.5rem;
}

.product-item .product-item-button {
  display: flex;
  align-items: center;
}