.bids {
  padding: 1rem;
}

.bids table tr td,
.bids table tr th {
  vertical-align: middle;
  text-align: center;
}

.bids tbody .bid-item:first-child td {
  background-color: var(--bs-success);
  color: white;
}

.bids .bid-item p {
  text-align: center;
}

.bids .bid-item button {
  margin-inline: 0.5rem;
}

.bids .add-bids button {
  margin-block: 1rem;
  width: 100%;
}

.bids .product-overview {
  display: flex;
  gap: 1rem;
}

.bids .product-overview p:not(:first-of-type) {
  margin: 0px;
}

.bids .product-overview .image-container {
  flex-basis: max(10%, 150px);
}

.bids .no-data {
  text-align: center;
  margin-block: 1rem;
  font-size: 1.25rem;
  font-weight: var(--semibold);
}
