.place-bid form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  width: min(100%, 360px);
  margin: 0 auto;
  padding: 1rem;
  background-color: var(--bs-gray-400);
}

.place-bid form input {
  display: block;
  width: 100%;
}
