@import "custom";
@import "react-datepicker/dist/react-datepicker.css";

:root {
  --primary-color: #916953;
  --secondary-color: #cf8e80;
  --tertiary-color: #052f5f;
  --primary-color-1: #a8c7bb;
  --primary-color-2: #2e86ab;

  --primary-contrast-text: #ffffff;

  --bold: 600;
  --semibold: 500;
  --normal: 400;
  --lighter: 300;

  --inactive-color: #d7d7d7;

  --spacing-1: 0.625rem;
}

.app {
  background-color: #f5f5f5;
}

.app > div {
  min-height: 100vh;
  background-color: #ffffff;
  background-image: url("assets/backgrounds/background.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  margin: 0 auto;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

label {
  display: block;
  font-weight: var(--semibold);
}

button {
  appearance: none;
  padding: 0.5em 1em;
  border: none;
  cursor: pointer;
  height: max-content;
}

button[type="submit"] {
  display: block;
  background-image: linear-gradient(251.66deg, #d33e22 8.61%, #f58634 103.17%);
  border: none;
  border-radius: 10px;
  width: 100%;
  margin-block: 1rem;
  padding-block: 0.75rem;
  font-weight: var(--bold);
}

button[type="submit"]:disabled {
  background-image: none;
}

.btn-primary {
  --bs-btn-disabled-bg: #d7d7d7;
  --bs-btn-disabled-opacity: 1;
}

button.primary {
  background-color: var(--primary-color);
  color: var(--primary-contrast-text);
}

img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

.input-group > .form-control,
.input-group > .dropdown-toggle {
  --bs-border-radius: 10px;
  padding: 0.5rem 0.75rem;
}

.success {
  color: var(--bs-success);
}

.danger {
  color: var(--bs-danger);
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.link-button {
  border: none;
  background: none;
  padding: 0px;
  margin: 0px;
}

.icon-button {
  border: none;
  background: none;
  padding: 0px 1em;
  aspect-ratio: 1/1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
