.settings .menu-item {
  display: grid;
  grid-template-columns: 48px minmax(0, 1fr);
  border-bottom: 1px solid var(--bs-gray);
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
}

.settings .menu-item > div:first-child {
  font-size: 1.5rem;
}

.settings .menu-item-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
